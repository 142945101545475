import React, { FC, useState, useEffect } from 'react';
import parse from "html-react-parser";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { NavLink  } from "react-router-dom";

type Article = {
    title: string;
    author: string;
    text: string;
};

type props = {
    title: string;
    description: string;
    src: string;
};

export const PageArticle: FC<props> = ({ title = "title", description = "description", src }) => {
    const [articleText, setArticleText] = useState<string>();

    const loadArticleData = async () => {
        console.log("loading article...");
        const res = await fetch(src);
        console.log("loaded article res", res);
        const textData: string = await res.text();
        setArticleText(textData);
        console.log("loaded gallery textData", textData);
        //const jsonData: Article = await res.json();
        //console.log("loaded article jsonData", jsonData);
        //setArticleText(jsonData);
    };

    useEffect(() => {
        loadArticleData();
        return () => { };
    }, []);     //[] - only load the first time this component rendered

    return (
        <>
            <Box sx={{ maxWidth: '90%', textAlign: 'center', ml: 'auto', mr: 'auto', mb: 2 }}>
                <Button href="/standard-portrait-painting-sizes">Standard Portrait Painting Sizes</Button>
                <Button href="/portrait-sitting-information">Portrait Sitting Information</Button>
                <Button href="/portrait-commissions">Portrait Commissions</Button>
            </Box>
            <Typography variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {description}
            </Typography>
            <Typography variant="h6" component="div" sx={{ maxWidth: 800, textAlign: 'left', ml: 'auto', mr: 'auto' }}>
                <Box sx={{ maxWidth: 800, textAlign: 'left', ml: 'auto', mr: 'auto', mb: 10, mt: 4 }}>
                    {articleText ? parse(articleText) : ""}
                </Box>
            </Typography>
        </>
    );
}