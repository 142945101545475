import React, { FC, useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

type MenuList = SubMenuList[];
type SubMenuList = MenuItem[];
type MenuItem = {
    title: string;
    link: string;
    icon?: string;
};

type props = {
    title: string;
    menuList: MenuList;
};

export const SideMenu: FC<props> = ({ title = "title", menuList }) => {
    const [state, setState] = React.useState({
        left: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Typography id="spring-modal-title" variant="h6" component="h2" sx={{ ml: 2, mt: 2, mb: 2 }}>
                <Button>{title}</Button>
            </Typography>
            {menuList.map((subList, index) => (
                <>
                    <Divider />
                    <List>
                        {subList.map((menuItem, index2) => (
                            <ListItem key={index2} disablePadding>
                                <ListItemButton component="a" href={menuItem.link}>
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={menuItem.title} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            ))}
        </Box>
    );

    const anchor: Anchor = 'left';

    return (
        <div>
            <React.Fragment key={anchor}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ ml: 5, mr: 5, mt: 20, backgroundColor: 'rgba(0,0,0,0.3)' }}
                    onClick={toggleDrawer(anchor, true)}
                >
                    {state[anchor] ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {list(anchor)}
                </Drawer>
            </React.Fragment>
        </div>
    );
}
