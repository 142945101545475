import React, { FC, useState, useRef } from 'react';

import { GridColumnHeaderClassNamePropType } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Gallery } from '../components/Gallery';

type GalleryItem = {
    src: string;
    thumb: string;
    title: string;
    description: string;
};
type GalleryDef = {
    title: string;
    subLink: string;
    items: GalleryItem[];
};
type GalleryText = {
    title: string;
    body: string;
};
type GalleryPage = {
    title: string;
    url: string;
    author: string;
    text: GalleryText;
    galleries: GalleryDef[];
};

type props = {
    title: string;
    description: string;
    gallery?: GalleryPage;
};

const handleScroll = (ref: any) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

export const GalleryIllustration: FC<props> = ({ title = "title", description = "description", gallery = null }) => {
    console.log("GalleryIllustration using", gallery);

    const scrollToTop = useRef<HTMLDivElement>(null);
    const scrollToSection = useRef(new Array());

    return (
        <>
            <Typography ref={scrollToTop} variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {description}
            </Typography>
            {gallery && gallery.galleries.length > 1 ?
                <Stack
                    sx={{ pt: 4 }}
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                >
                    {gallery ? gallery.galleries.map((galleryDef, index) =>
                        <Button onClick={() => {handleScroll(scrollToSection.current[index])}} variant="outlined">{galleryDef.title}</Button>
                    )
                    : ""}
                </Stack>
                : ""}
            {gallery ? gallery.galleries.map((galleryDef, index) => (
                <div key={index} ref={(element) => scrollToSection.current.push(element)}>
                    <Gallery title={galleryDef.title} description={""} items={galleryDef.items} subLink={galleryDef.subLink} />
                    <Button sx={{ mb: 10 }} onClick={() => {handleScroll(scrollToTop.current)}} variant="outlined">Back to top</Button>
                </div>
            ))
            : ""}
        </>
    );
}