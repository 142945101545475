import React, { FC, useState, useEffect } from 'react';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

// web.cjs is required for IE11 support
import { useSpring, animated } from '@react-spring/web';

interface FadeProps {
    children?: React.ReactElement;
    in: boolean;
    onEnter?: () => {};
    onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 960,
    height: 1024,
    maxWidth: '90%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
};

type props = {
    state: boolean;
    title: string;
    description: string;
    src: string;
    handleCloseModal: Function;
};

export const SpringModal: FC<props> = ({ state = false, title = "title", description = "-", src = "", handleCloseModal }) => {
    console.log("GalleryModal component state initialised to state=" + state);

    const handleClose = () => {
        console.log("GalleryModal clicked closed");
        state = false;
        handleCloseModal();
    };

    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={state}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={state}>
                    <Box sx={style}>
                        <CardMedia
                            component="img"
                            sx={{
                                // 16:9
                                pb: 2,
                                maxHeight: '90%',
                                objectFit: "contain" 
                            }}
                            image={src}
                            alt={title}
                        />
                        <Typography id="spring-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                            {description}
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
