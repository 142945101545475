import React, { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

type props = {
    title: string;
    link: string;
    dev: string;
};

export const Copyright: FC<props> = ({ title = "title", dev="developer", link = "#" }) => {
    return (
        <div className="copyright-panel">
        <Typography variant="body2" color="#fff" align="center">
            {'Copyright ©'}
            {new Date().getFullYear()}
            {' '}
            {title}
            {'. All rights are reserved.'}
        </Typography>
        <Typography variant="body2" color="#fff" align="center">
            {'Proudly developed by '}
            <Link color="inherit" href={link}>
                {dev}
            </Link>
            {'.'}
        </Typography>
        </div>
    );
};