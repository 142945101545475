import React, { FC, useState } from 'react';

import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Copyright } from './Copyright';

const theme = createTheme();

type props = {
    title: string;
    link: string;
    dev: string;
};

export const Footer: FC<props> = ({ title = "title", dev="developer", link = "#" }) => {
    return (
    <Box sx={{ bgcolor: '#272727', p: 6 }} component="footer">
        <Copyright title={"Tony Gillan"} dev={"Skylight Creative"} link={"https://skylightcreative.com.au"} />
    </Box>
    );
}