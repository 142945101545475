import React, { FC, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

import { SpringModal } from "./GalleryModal";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
type GalleryItem = {
    src: string;
    thumb: string;
    title: string;
    description: string;
};

type props = {
    title: string;
    description: string;
    items: GalleryItem[];
    subLink: string;
};

export const Gallery: FC<props> = ({ title = "title", description = "description", items, subLink = "" }) => {
    const [open, setOpen] = useState(false);

    const [currentImageTitle, setCurrentImageTitle] = useState("");
    const [currentImageDescription, setCurrentImageDescription] = useState("");
    const [currentImageSource, setCurrentImageSource] = useState("");

    const handleOpen = (card: GalleryItem) => {
        console.log("Gallery view clicked open with", card);
        setCurrentImageTitle(card.title);
        setCurrentImageDescription(card.description);
        setCurrentImageSource(card.src);
        setOpen(true);
    };
    const handleClose = () => {
        console.log("Gallery view clicked closed");
        setOpen(false);
    };

    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    pt: 2,
                    pb: 1,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {description}
                    </Typography>
                </Container>
            </Box>
            <Container sx={{ py: 8 }} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    {items.map((card) => (
                        <Grid item key={card.title} xs={12} sm={6} md={4}>
                            <Card
                                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                            >
                                <CardMedia
                                    component="img"
                                    sx={{
                                        // 16:9
                                        pt: 5,
                                        width: 150,
                                        mx: "auto"
                                    }}
                                    image={card.thumb}
                                    alt={card.title}
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h6" component="h2">
                                        {card.title}
                                    </Typography>
                                    <Typography>
                                        {card.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => handleOpen(card)}>View</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <SpringModal state={open} title={currentImageTitle} description={currentImageDescription} src={currentImageSource} handleCloseModal={handleClose} />
        </main>
    );
}