import React, { FC, useState } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { GalleryImage } from '../components/GalleryImage';

type ImageItem = {
    title: string;
    description: string;
    src: string;
    link: string;
};

type props = {
    title: string;
    description: string;
};

const imageList: ImageItem[] = [
    {
        title: "Portraiture and Figurative Works",
        description: "",
        src: "./resources/artwork/gallery/banner/banner06.jpg",
        link: "/portraits",
    },
    {
        title: "Landscapes and Interiors",
        description: "",
        src: "./resources/artwork/gallery/banner/banner02.jpg",
        link: "/landscapes",
    },
    {
        title: "Illustrations",
        description: "",
        src: "./resources/artwork/gallery/banner/banner04.jpg",
        link: "/illustrations",
    },
    {
        title: "Life Drawings",
        description: "",
        src: "./resources/artwork/gallery/banner/banner03.jpg",
        link: "/life",
    },
    {
        title: "20 Days Exhibition",
        description: "",
        src: "./resources/artwork/gallery/banner/banner01.jpg",
        link: "/20-days",
    },
];

export const PageHome: FC<props> = ({ title = "title", description = "description" }) => {
    return (
        <>
            <h1>{title}</h1>
            <p>{description}</p>
            <Container sx={{ py: 8 }} maxWidth="lg">
                <Grid container spacing={4}>
                {imageList.map((imageItem, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <GalleryImage title={imageItem.title} description={imageItem.description} src={imageItem.src} link={imageItem.link} />
                    </Grid>
                ))}
                </Grid>
            </Container>
        </>
    );
}