import React, { FC, useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { SideMenu } from './SideMenu';

import headerBackground from "./resources/artwork/gallery/teale/ptea01_teale_14_960_w.jpg";

const theme = createTheme();

type MenuList = SubMenuList[];
type SubMenuList = MenuItem[];
type MenuItem = {
    title: string;
    link: string;
    icon?: string;
};

type props = {
    title: string;
    subtitle: string;
    menuList: MenuList;
    fullSize: boolean;
};

export const Banner: FC<props> = ({ title = "title", subtitle = "subtitle", menuList, fullSize = true }) => {
    return (
        <Box sx={{ flexGrow: 1, mb: 2 }}>
            <AppBar position="static" sx={{ height: 400, background: 'url("./resources/artwork/gallery/banner/paint028_warwickfence_07_banner.jpg") no-repeat center center', backgroundSize: 'cover'  }}>
                <Toolbar>
                    <Box sx={{ mb: 10, ml: 'auto', mr: 'auto', display: 'block' }}>
                        <Box sx={{ position: 'absolute', left: 0 }}>
                            <SideMenu title="Menu" menuList={menuList} />
                        </Box>
                        <Typography variant="h2" component="div" sx={{ mt: 14, mb: 4, ml: 'auto', mr: 'auto', display: 'block', fontFamily: "'Sanchez', sans-serif" }}>
                            {title}
                        </Typography>
                        <Typography variant="h5" component="div" sx={{ml: 'auto', mr: 'auto', display: 'block'  }}>
                            {subtitle}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}