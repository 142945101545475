import React, { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { NavLink  } from "react-router-dom";

type props = {
    title: string;
    description: string;
};

export const PageArticleList: FC<props> = ({ title = "title", description = "description" }) => {
    return (
        <>
            <Typography variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {description}
            </Typography>
            <Typography variant="h6" component="div" sx={{ maxWidth: 800, textAlign: 'left', ml: 'auto', mr: 'auto' }}>
                <Box sx={{ maxWidth: 640, textAlign: 'left', ml: 'auto', mr: 'auto' }}>
                    <ul>
                        <li><NavLink  to="/standard-portrait-painting-sizes">Standard Portrait Painting Sizes</NavLink ></li>
                        <li><NavLink  to="/portrait-sitting-information">Portrait Sitting Information</NavLink ></li>
                        <li><NavLink  to="/portrait-commissions">Portrait Commissions</NavLink ></li>
                    </ul>
                </Box>
            </Typography>
        </>
    );
}