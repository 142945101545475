import React, { FC, useState } from 'react';

import Typography from '@mui/material/Typography';

type props = {
    title: string;
    description: string;
};

export const NoPage: FC<props> = ({ title = "title", description = "description" }) => {
    return (
        <>
            <Typography variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {description}
            </Typography>
        </>
    );
}