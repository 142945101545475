import React, { useState, useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GallerySite } from './components/GallerySite';

import { NoPage } from "./pages/NoPage";
import { GalleryIllustration } from "./pages/GalleryIllustration";
import { GalleryLandscape } from "./pages/GalleryLandscape";
import { GalleryLife } from "./pages/GalleryLife";
import { GalleryPortrait } from "./pages/GalleryPortrait";
import { GalleryTwenty } from "./pages/GalleryTwenty";
import { PageAbout } from "./pages/PageAbout";
import { PageArticleList } from "./pages/PageArticleList";
import { PageArticle } from "./pages/PageArticle";
import { PageHome } from "./pages/PageHome";

import './App.css';

type GalleryItem = {
  src: string;
  thumb: string;
  title: string;
  description: string;
};
type GalleryDef = {
  title: string;
  subLink: string;
  items: GalleryItem[];
};
type GalleryText = {
  title: string;
  body: string;
};
type GalleryPage = {
  title: string;
  url: string;
  author: string;
  text: GalleryText;
  galleries: GalleryDef[];
};
type GalleryList = {
  pages: GalleryPage[];
};

function App() {
  const [galleryList, setgalleryList] = useState<GalleryList>();

  const loadGalleryData = async () => {
    console.log("loading gallery...");
    const res = await fetch('/config.json');
    console.log("loaded gallery res", res);
    //const textData: string = await res.text();
    //console.log("loaded gallery textData", textData);
    const jsonData: GalleryList = await res.json();
    console.log("loaded gallery jsonData", jsonData);
    setgalleryList(jsonData);
  };

  const getGallery = (title: string) => {
    let output: GalleryPage | undefined;
    if (galleryList && galleryList.pages) {
      for (let i=0, len=galleryList.pages.length; i<len; i++) {
        if (galleryList.pages[i].title===title) {
          output = galleryList.pages[i];
        }
      }
    }
    return output;
  };

  useEffect(() => {
    loadGalleryData();
    return () => { };
  }, []);     //[] - only load the first time this component rendered

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<GallerySite title={"Tony Gillan"} subtitle={"Gallery of Artwork"} />}>
            <Route index element={<PageHome title="Home" description="" />} />
            <Route path="/illustrations" element={<GalleryIllustration title="Illustrations" description="" gallery={getGallery("Illustrations")} />} />
            <Route path="/landscapes" element={<GalleryLandscape title="Landscapes" description="" gallery={getGallery("Landscapes")} />} />
            <Route path="/life" element={<GalleryLife title="Life Drawings" description="" gallery={getGallery("Life Drawings")} />} />
            <Route path="/portraits" element={<GalleryPortrait title="Portraiture & Figurative Works" description="" gallery={getGallery("Portraiture & Figurative Works")} />} />
            <Route path="/20-days" element={<GalleryTwenty title="20 Days" description="" gallery={getGallery("20 Days")} />} />
            <Route path="/about" element={<PageAbout title="About" description="" />} />
            <Route path="/articles" element={<PageArticleList title="Articles & Information" description="" />} />
            <Route path="/standard-portrait-painting-sizes" element={<PageArticle title="Standard Portrait Painting Sizes" description="" src="/painting-sizes.html" />} />
            <Route path="/portrait-sitting-information" element={<PageArticle title="Portrait Sitting Information" description="" src="/sitting-info.html" />} />
            <Route path="/portrait-commissions" element={<PageArticle title="Portrait Commissions" description="" src="/commissions.html" />} />
            <Route path="*" element={<NoPage title="Page Not Found" description="" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

