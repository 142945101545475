import React, { FC, useState } from 'react';
import {Outlet} from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { SideMenu } from './SideMenu';
import { Banner } from './Banner';
import { Footer } from './Footer';

type MenuList = SubMenuList[];
type SubMenuList = MenuItem[];
type MenuItem = {
    title: string;
    link: string;
    icon?: string;
};

const menuList: MenuList = [
    [
        {title:'Home', link:'/'},
        {title:'Articles', link:'/articles'},
        {title:'About', link:'/about'}],
    [
        {title:'20 Days', link:'/20-days'},
        {title:'Portraiture & Figurative', link:'/portraits'},
        {title:'Landscapes', link:'/landscapes'},
        {title:'Life Drawings', link:'/life'},
        {title:'Illustrations', link:'/illustrations'}],
];

const theme = createTheme();

type props = {
    title: string;
    subtitle: string;
};

export const GallerySite: FC<props> = ({ title = "title", subtitle = "" }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Banner title={title} subtitle={subtitle} menuList={menuList} fullSize={true} />
            <Outlet />
            <Footer title={"Tony Gillan"} dev={"Skylight Creative"} link={"https://skylightcreative.com.au"} />
        </ThemeProvider>
    );
}