import React, { FC, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

type props = {
    title: string;
    description: string;
};

export const PageAbout: FC<props> = ({ title = "title", description = "description" }) => {
    return (
        <>
            <Typography variant="h4" component="div">
                {title}
            </Typography>
            <Typography variant="h5" component="div">
                {description}
            </Typography>
            <Card sx={{ width: 200, ml: 'auto', mr: 'auto', mt: 2, mb: 2 }} >
                <CardMedia
                    component="img"
                    sx={{ p: 0 }}
                    image="./resources/artwork/gallery/illustrations/art_studio_01.png"
                    alt="art studio"
                />
            </Card>
            <Typography variant="h6" component="div" sx={{ maxWidth: 800, textAlign: 'left', ml: 'auto', mr: 'auto' }}>
                <p>Tony Gillan is a fine-artist specialising in portraiture and figurative paintings. He grew up in Brisbane and is now living and working in Melbourne Australia.</p>
                <p>Tony has just finished his second body of work titled "20 Days". The exhibition featured 20 main paintings and many supporting drawings produced around the theme of 20 creative "days".</p>
                <p>He has studied Fine-Art, Graphic Design, Software Engineering at various Universities and Colleges in Australia. Exhibitions and competition showings include:</p>
                <Box sx={{ maxWidth: 640, textAlign: 'left', ml: 'auto', mr: 'auto' }}>
                    <ul>
                        <li>20 Days Exhibition, 2015</li>
                        <li>Station Street Cafe, Nunawading VIC, 2011</li>
                        <li>The Sanctuary – Ashburton Baptist Church, Ashburton VIC, 2006</li>
                        <li>Foyer – Wesley Mission Ipswich, QLD</li>
                        <li>Redlands Art Awards, Capalaba 1999</li>
                        <li>Graduation Exhibition, Eagle Farm, QLD 1991</li>
                    </ul>
                </Box>
            </Typography>
            <Typography variant="h6" component="div" sx={{ maxWidth: 800, textAlign: 'center', ml: 'auto', mr: 'auto', mb: 10 }}>
                <p>Contact:<br />
                    <a href="mailto:info@tonygillan.com">info@tonygillan.com</a></p>
            </Typography>
        </>
    );
}